<template>
  <div>
    <b-overlay :show="isLoading" class="d-flex" style="gap: 12px">
      <!-- Seitenleiste -->
      <div style="min-width: 140px">
        <div class="card p-6" style="gap: 12px; align-items: center; width: 100%">
          <b-badge v-if="saisonpreise?.length === 0">Jahr hinzufügen</b-badge>
          <div
            v-for="(jahr, i) in saisonJahre"
            :key="'jahr-' + jahr"
            class="p-3 rounded"
            style="cursor: pointer; width: 100%"
            :class="activeYear === jahr ? 'bg-primary text-white' : 'bg-secondary'"
            @click="activeYear = jahr"
          >
            <span class="d-flex">
              {{ `${i + 1}. Jahr ${jahr}` }}
            </span>
          </div>
          <div class="bg-primary plus-sign" v-b-modal.modalJahrHinzufuegen>+</div>
        </div>
      </div>
      <!-- Show active season -->
      <div class="p-6 card card-body" style="min-width: 900px">
        <h4 class="w-25">TH / VK Max</h4>
        <b-form-group label="Wettbewerber" class="mt-4 pl-4">
          <b-form-input
            v-model="wettbewerberNotizen"
            @update="onWettbewerberNotizenChange"
            debounce="750"
            style="width: 200px"
          ></b-form-input
        ></b-form-group>
        <div class="d-flex ml-auto stickyDiv" style="gap: 12px; margin-top: -24px">
          <b-button @click="saveChanges" variant="primary">Speichern</b-button>
          <b-button @click="deleteYear" variant="secondary" v-if="saisonpreise?.length > 1"
            >Jahr löschen</b-button
          >
        </div>
        <div class="d-flex mt-2" style="gap: 24px" v-if="saisonpreise?.length > 0">
          <div style="width: 100px"></div>
          <div style="width: 200px">TH Max Langstrecke</div>
          <div style="width: 200px">TH Max Inlandsflug</div>
          <div style="width: 200px">VK Max</div>
          <div style="width: 200px">Kommentar</div>
        </div>
        <div
          v-for="saisonpreis in formVariables"
          :key="'saisonpreis-' + saisonpreis.gueltigAb"
          style="gap: 24px"
          class="d-flex align-items-center mt-4"
        >
          <div class="d-flex justify-content-center" style="width: 100px">
            Ab {{ saisonpreis.formattedGueltigAb }}
          </div>
          <b-form-group class="form-group">
            <b-form-input
              size="sm"
              v-model="saisonpreis.thMaxLangstrecke"
              :number="true"
              type="number"
              min="0"
              max="365"
              step="1"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group style="width: 200px">
            <b-form-input
              size="sm"
              v-model="saisonpreis.thMaxInlandsflug"
              :number="true"
              type="number"
              min="0"
              max="365"
              step="1"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group style="width: 200px">
            <b-form-input
              size="sm"
              v-model="saisonpreis.vkMax"
              :number="true"
              type="number"
              min="0"
              max="365"
              step="1"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group style="width: 200px">
            <b-form-input size="sm" v-model="saisonpreis.kommentar" required></b-form-input>
          </b-form-group>
          <div class="ml-2" @click="deleteSaisonpreis(saisonpreis.gueltigAb)" style="cursor: pointer">
            <i class="fas fa-trash" style="color: black"> </i>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-center" style="width: 100px">
          <div class="bg-primary plus-sign" v-b-modal.modalSaisonpreisHinzufuegen>+</div>
        </div>
      </div>
    </b-overlay>
    <b-modal id="modalJahrHinzufuegen" title="Jahr hinzufügen" size="sm">
      <b-overlay :show="isModalLoading">
        <b-form-group label="Jahr">
          <DatePicker :lang="langConfig" type="year" format="YYYY" v-model="saisonYear"></DatePicker>
        </b-form-group>
      </b-overlay>
      <template #modal-footer="{ hide }" class="pl-1">
        <b-button class="mr-4" @click="addYear(hide)" :disabled="saisonYear === null" variant="success"
          >Erstellen</b-button
        >
        <b-button @click="hide">Abbrechen</b-button>
      </template>
    </b-modal>
    <b-modal id="modalSaisonpreisHinzufuegen" title="Saisonpreis hinzufügen" size="sm">
      <b-overlay :show="isModalLoading">
        <div class="d-flex align-items-center" style="gap: 16px; flex-direction: column">
          <b-form-group label="Gültig ab">
            <DatePicker
              :lang="langConfig"
              type="date"
              format="DD.MM.YYYY"
              v-model="localSaisonpreis.gueltigAb"
            ></DatePicker>
          </b-form-group>
        </div>
      </b-overlay>
      <template #modal-footer="{ hide }" class="pl-1">
        <b-button
          class="mr-4"
          @click="addSaisonpreis(hide)"
          :disabled="disableSichernButtonSaisonpreis"
          variant="success"
          >Erstellen</b-button
        >
        <b-button @click="hide">Abbrechen</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { compareDesc, format, getYear } from 'date-fns';
import apiService from '@/core/common/services/api.service';
import DatePicker from 'vue2-datepicker';
import { formatDateWithoutHours, langConfig } from '@/core/common/helpers/utils.js';
import { UPDATE_REISE_WETTBEWERBER_NOTIZEN } from '../../../core/produkte/reisen/stores/reisen.module';

export default {
  name: 'reisen-saisonpreise-view',
  props: {
    reise: Object,
  },
  components: { DatePicker },
  data() {
    return {
      langConfig,
      activeYear: null,
      isLoading: false,
      isModalLoading: false,
      saisonYear: null,
      formVariables: [],
      saisonpreisByYear: {},
      localSaisonpreis: {
        gueltigAb: null,
      },
      wettbewerberNotizen: '',
    };
  },
  mounted() {
    if (this.saisonJahre.length > 0 && this.activeYear === null) {
      this.activeYear = this.saisonJahre[0];
    }
    if (this.initialReiseSaisonpreise) {
      this.saisonpreisByYear = this.initialReiseSaisonpreise.reduce((acc, curr) => {
        const year = getYear(new Date(curr.gueltigAb));
        if (!acc.hasOwnProperty(year.toString())) {
          acc[year] = [
            {
              gueltigAb: curr.gueltigAb,
              thMaxInlandsflug: curr.thMaxInlandsflug,
              thMaxLangstrecke: curr.thMaxLangstrecke,
              vkMax: curr.vkMax,
              kommentar: curr.kommentar,
            },
          ];
        } else {
          acc[year] = [
            ...acc[year],
            {
              gueltigAb: curr.gueltigAb,
              thMaxInlandsflug: curr.thMaxInlandsflug,
              thMaxLangstrecke: curr.thMaxLangstrecke,
              vkMax: curr.vkMax,
              kommentar: curr.kommentar,
            },
          ];
        }
        return acc;
      }, {});
    }

    this.initFormVariables(this.saisonpreisByYear[this.activeYear]);
  },
  watch: {
    activeYear(v) {
      this.initFormVariables(this.saisonpreisByYear[v]);
    },
    initialReiseSaisonpreise(v) {
      if (this.saisonJahre.length > 0 && this.activeYear === null) {
        this.activeYear = this.saisonJahre[0];
      }
      this.saisonpreisByYear = v.reduce((acc, curr) => {
        const year = getYear(new Date(curr.gueltigAb));
        if (!acc.hasOwnProperty(year.toString())) {
          acc[year] = [
            {
              gueltigAb: curr.gueltigAb,
              thMaxInlandsflug: curr.thMaxInlandsflug,
              thMaxLangstrecke: curr.thMaxLangstrecke,
              vkMax: curr.vkMax,
              kommentar: curr.kommentar,
            },
          ];
        } else {
          acc[year] = [
            ...acc[year],
            {
              gueltigAb: curr.gueltigAb,
              thMaxInlandsflug: curr.thMaxInlandsflug,
              thMaxLangstrecke: curr.thMaxLangstrecke,
              vkMax: curr.vkMax,
              kommentar: curr.kommentar,
            },
          ];
        }
        return acc;
      }, {});

      this.initFormVariables(this.saisonpreisByYear[this.activeYear]);
    },
  },
  computed: {
    disableSichernButtonSaisonpreis() {
      return this.localSaisonpreis.gueltigAb === null;
    },
    saisonpreise() {
      return this.reise?.saisonpreise
        .map(item => ({
          ...item,
          formattedGueltigAb: formatDateWithoutHours(item.gueltigAb),
        }))
        .slice()
        .sort((a, b) => compareDesc(new Date(a.gueltigAb), new Date(b.gueltigAb)));
    },
    saisonJahre() {
      return [
        ...new Set(this.saisonpreise?.map(saisonpreis => format(new Date(saisonpreis.gueltigAb), 'yyyy'))),
      ];
    },
    initialReiseSaisonpreise() {
      return this.reise?.saisonpreise;
    },
  },
  methods: {
    initFormVariables(v) {
      const keys = ['thMaxLangstrecke', 'thMaxInlandsflug', 'vkMax', 'gueltigAb', 'kommentar'];
      this.formVariables = v
        ?.reduce((acc, curr) => {
          const saisonpreise = {};
          for (const key of keys) {
            saisonpreise[key] = curr[key];
          }
          saisonpreise.formattedGueltigAb = formatDateWithoutHours(curr.gueltigAb).substring(0, 6);
          acc.push(saisonpreise);
          return acc;
        }, [])
        .sort((a, b) => compareDesc(new Date(b.gueltigAb), new Date(a.gueltigAb)));

      this.wettbewerberNotizen = this.reise?.wettbewerberNotizen;
    },
    onReiseUpdated() {
      this.$emit('reiseUpdated');
    },
    addYear(hide) {
      if (this.saisonJahre?.length === 0) {
        this.addFirstYear();
      } else {
        this.copyExistingYear();
      }
      hide();
    },
    addSaisonpreis(hide) {
      this.isModalLoading = true;
      const reisekuerzel = this.reise.reisekuerzel;
      const gueltigAb = format(this.localSaisonpreis.gueltigAb, 'yyyy-MM-dd');
      if (!this.saisonpreise.some(saison => new Date(saison.gueltigAb) === this.gueltigAb)) {
        apiService
          .post(`Reise/${reisekuerzel}/Saisonpreise/${gueltigAb}`, {
            thMaxLangstrecke: 0,
            thMaxInlandsflug: 0,
            vkMax: 0,
            kommentar: '',
          })
          .then(() => {
            this.toast('Saisonpreis erfolgreich hinzugefügt.');
            this.isModalLoading = false;
            this.onReiseUpdated();
          })
          .catch(() => (this.isModalLoading = false));
      }
      this.resetLocalSaisonpreis();
      hide();
    },
    copyExistingYear() {
      this.isModalLoading = true;
      const reisekuerzel = this.reise.reisekuerzel;
      const yearFormatted = format(this.saisonYear, 'yyyy');

      apiService
        .post(`Reise/${reisekuerzel}/Saisonpreise/${yearFormatted}/Copy/${this.activeYear}`)
        .then(() => {
          this.toast('Jahr erfolgreich hinzugefügt.');
          this.isModalLoading = false;
          this.onReiseUpdated();
          this.saisonYear = null;
        })
        .catch(() => (this.isModalLoading = false));
    },
    addFirstYear() {
      this.isModalLoading = true;
      const reisekuerzel = this.reise.reisekuerzel;
      const gueltigAb = format(this.saisonYear, 'yyyy-MM-dd');
      if (!this.saisonpreise.some(saison => new Date(saison.gueltigAb) === this.saisonYear)) {
        apiService
          .post(`Reise/${reisekuerzel}/Saisonpreise/${gueltigAb}`, {
            thMaxLangstrecke: 0,
            thMaxInlandsflug: 0,
            vkMax: 0,
            kommentar: '',
          })
          .then(() => {
            this.toast('Jahr erfolgreich hinzugefügt.');
            this.isModalLoading = false;
            this.onReiseUpdated();
            this.saisonYear = null;
          })
          .catch(() => (this.isModalLoading = false));
      }
    },
    saveChanges() {
      this.isLoading = true;
      const reisekuerzel = this.reise.reisekuerzel;
      const params = this.formVariables.reduce((acc, curr) => {
        const formattedGueltigAb = format(new Date(curr.gueltigAb), 'yyyy-MM-dd');

        acc[formattedGueltigAb] = {
          thMaxLangstrecke: curr.thMaxLangstrecke,
          thMaxInlandsflug: curr.thMaxInlandsflug,
          vkMax: curr.vkMax,
          kommentar: curr.kommentar,
        };
        return acc;
      }, {});
      apiService
        .put(`Reise/${reisekuerzel}/Saisonpreise`, params)
        .then(() => {
          this.toast('TH / VK Max erfolgreich verändert.');
          this.isLoading = false;
          this.onReiseUpdated();
        })
        .catch(() => (this.isModalLoading = false));
      this.isLoading = false;
    },
    async deleteYear() {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        `Möchtest du die TH / VK Max des Jahres ${this.activeYear} wirklich löschen?`,
        {
          centered: true,
          okTitle: 'Löschen',
          okVariant: 'danger',
          cancelTitle: 'Abbrechen',
        }
      );
      const reisekuerzel = this.reise.reisekuerzel;

      if (confirmed) {
        this.isLoading = true;
        apiService
          .delete(`Reise/${reisekuerzel}/Saisonpreise/Years?year=${+this.activeYear}`)
          .then(() => {
            this.toast(`Jahr ${this.activeYear} erfolgreich gelöscht.`);
            this.activeYear = null;
            this.onReiseUpdated();
            this.isLoading = false;
          })
          .catch(() => (this.isLoading = false));
      }
    },
    async deleteSaisonpreis(gueltigAb) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        `Möchtest du die TK / VK Max ab ${format(new Date(gueltigAb), 'dd.MM.')} wirklich löschen?`,
        {
          centered: true,
          okTitle: 'Löschen',
          okVariant: 'danger',
          cancelTitle: 'Abbrechen',
        }
      );
      const reisekuerzel = this.reise.reisekuerzel;

      if (confirmed) {
        this.isLoading = true;
        apiService
          .delete(`Reise/${reisekuerzel}/Saisonpreise/${gueltigAb}`)
          .then(() => {
            this.toast(`Jahr ${this.activeYear} erfolgreich gelöscht.`);
            if (this.formVariables.length === 1) {
              this.activeYear = null;
            }
            this.onReiseUpdated();
            this.isLoading = false;
          })
          .catch(() => (this.isLoading = false));
      }
    },
    resetLocalSaisonpreis() {
      this.localSaisonpreis = {
        gueltigAb: null,
      };
    },
    onWettbewerberNotizenChange(value) {
      this.$store
        .dispatch(UPDATE_REISE_WETTBEWERBER_NOTIZEN, {
          wettbewerberNotizen: value,
          reisekuerzel: this.reise.reisekuerzel,
        })
        .then(() => {
          this.toast('Erfolgreich bearbeitet');
          this.$emit('reiseUpdated');
        })
        .catch(() => {
          // reset
          this.wettbewerberNotizen = this.reise?.wettbewerberNotizen;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.plus-sign {
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.stickyDiv {
  position: -webkit-sticky; /* for browser compatibility */
  position: sticky;
  top: 140px;
}

.form-group {
  width: 200px;
  margin-bottom: 0px;
}

:deep(.modal-footer) {
  padding: 5px;
}
</style>
